export const labels = {
  ptBr: {
    whatIs:
      "Landing Page é uma página que possui todos os elementos voltados à conversão do visitante em Lead, oportunidade ou cliente. Também conhecidas como páginas de aterrissagem ou páginas de conversão, as Landing Pages são muito usadas em campanhas de Marketing Digital, pois costumam ter altas taxas de conversão.",
  },
  en: {
    whatIs:
      "Landing Page is a page that contains all the elements that are aimed at converting the visitor into a Lead, Opportunity or Customer. Also known as Landing Pages, Landing Pages are very often used in Digital Marketing campaigns, because they often have high conversion rates.",
  },
};
