import { Link as LinkR } from "react-router-dom";
import styled from "styled-components";

import dark from "../../styles/themes/dark";
import light from "../../styles/themes/light";

export type logoProps = {
  scrollNav: boolean;
};

export const NavLogo = styled(LinkR)<logoProps>`
  color: ${(props) => (props.scrollNav ? light.colors.text : dark.colors.text)};
  /* color: ${(props) => props.theme.colors.text}; */
  justify-self: flex-start;
  cursor: pointer;
  font-size: 1.5rem;
  display: flex;
  align-items: center;
  margin-left: 24px;
  font-weight: bold;
  text-decoration: none;
`;

export const NavLogoDark = styled(LinkR)<logoProps>`
  color: ${(props) => (props.scrollNav ? dark.colors.text : dark.colors.text)};
  /* color: ${(props) => props.theme.colors.text}; */
  justify-self: flex-start;
  cursor: pointer;
  font-size: 1.5rem;
  display: flex;
  align-items: center;
  margin-left: 24px;
  font-weight: bold;
  text-decoration: none;
`;
