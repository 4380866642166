import axios from "axios";
import PostTopics from "components/PostTopics";
import { Markup } from "interweave";
import React from "react";
import { useNavigate,useParams } from "react-router-dom";

import commentsIcon from "../../assets/comments.svg";
import calenderIcon from "../../assets/date.svg";
import githubIcon from "../../assets/github-icon.svg";
import { InfoCard } from "../../components/InfoCard";
import { SmallInfo } from "../../components/InfoIcon";
import { LabelLink } from "../../components/LabelLink";
import { formateDateDistance } from "../../helpers/date";
import { GithubIssueData } from "../../hooks/useGitubIssues";
import {
  Container,
  LeftHeader,
  PostContent,
  PostFooter,
  PostHeader,
  PostInfoContainer,
  PostTitle,
  Topics,
} from "./styles";

const Post: React.FC = () => {
  const [post, setPost] = React.useState({} as GithubIssueData);
  const { post_id } = useParams();
  const navigate = useNavigate();

  React.useEffect(() => {
    (async () => {
      const res = await axios.get(
        `https://api.github.com/repos/estrng/blog/issues/${post_id}`
      );
      setPost(res.data);
    })();
  }, [post_id]);

  const handleGoBack = () => navigate(-1);

  const handleGoToHome = () => navigate("/");

  const hasData = () => Object.keys(post).length > 0;

  return (
    <Container>
      {!hasData ? (
        "Obtendo..."
      ) : (
        <>
          <InfoCard>
            <PostInfoContainer>
              <PostHeader>
                <LeftHeader>
                  <LabelLink label="< VOLTAR" onClick={handleGoBack} />
                  <LabelLink label="| HOME" onClick={handleGoToHome} />
                </LeftHeader>
                <LabelLink
                  href={`https://github.com/estrng/blog/issues/${post_id}`}
                  label="COMENTE NO GITHUB"
                  target="_blank"
                  icon
                />
              </PostHeader>
              <PostTitle>{post.title}</PostTitle>
              <PostFooter>
                <SmallInfo label={post.user?.login} icon={githubIcon} />
                <SmallInfo
                  label={formateDateDistance(post.created_at)}
                  icon={calenderIcon}
                />
                <SmallInfo
                  label={`${String(post.comments)} comentários`}
                  icon={commentsIcon}
                />
              </PostFooter>
            </PostInfoContainer>
          </InfoCard>
          <PostContent>
            <Markup content={post.body} />
            <Topics>
              tópicos:
              {post.labels &&
                post.labels.map((label) => (
                  <PostTopics color={label.color} children={label.name} />
                ))}
            </Topics>
          </PostContent>
        </>
      )}
    </Container>
  );
};

export default Post;
