export const logo = {
  ptBr: {
    logoAsName: "estrng",
    logoImageUrl: "https://i.imgur.com/lQiLVfW.png",
  },
  en: {
    logoAsName: "estrng",
    logoImageUrl: "https://i.imgur.com/lQiLVfW.png",
  },
};
// TODO: remove this file once all languages have been translated
