import styled from "styled-components";

export const Container = styled.div`
  width: 50%;

  display: grid;
  justify-content: space-between;
  grid-template-columns: auto auto;
  grid-template-rows: auto auto;
  column-gap: 24px;
  row-gap: 34px;

  margin-bottom: 68px;

  @media (max-width: 768px) {
    width: 90%;

    display: flex;
  }
`;
