import React, { useState } from "react";

import Footer from "./../../components/Footer";
import Header from "./../../components/Header";
import { homeObjOne } from "./../../sections/Info/data";
import Sections from "./imports";

const Home: React.FC = () => {
  const [isOpen, setIsOpen] = useState(false);

  function toggleIsOpen() {
    setIsOpen(!isOpen);
  }

  return (
    <>
      <Header toggle={toggleIsOpen} />
      <Sections.Sidebar isOpen={isOpen} toggle={toggleIsOpen} />
      <Sections.Hero />
      <Sections.Info {...homeObjOne} />
      <Footer />
    </>
  );
};

export default Home;
