import { Link } from "react-router-dom";
import styled from "styled-components";

export const Container = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
`;

export const LinkS = styled(Link)`
  color: ${(props) =>
    true
      ? props.theme.colors.text
      : "#fff"}; // TODO: change to theme.colors.text
  text-decoration: none;
  margin-bottom: 0.5rem;
  font-size: 14px;

  &:hover {
    color: #01bf71;
    transition: 0.3s ease-out;
  }
`;

export const TextContainer = styled.div`
  height: auto;
  width: 1280px;
  text-align: justify;
  border: 1px solid ${(props) => (true ? props.theme.colors.text : "#fff")};
  border-radius: 5px;
  padding: 3rem;
  margin-top: 2rem;
  margin-bottom: 2rem;
`;

export const BigTitle = styled.h1`
  font-size: 2.5rem;
  text-align: center;
  margin-bottom: 2rem;
`;

export const SubTitle = styled.h2`
  font-size: 1.5rem;
  margin-bottom: 2rem;
`;

export const Paragraph = styled.p`
  font-size: 1.2rem;
  margin-bottom: 1rem;
`;

export const ItensContainer = styled.ol`
  font-size: 1.2rem;
`;

export const Item = styled.li`
  margin-bottom: 1rem;
`;

export const FooterTitle = styled.h3`
  font-size: 1.2rem;
  margin-bottom: 1rem;
`;
