import styled from "styled-components";

export const Container = styled.div`
  width: 100%;
  height: 100%;

  display: flex;
  flex-direction: column;
  align-items: center;
  background: #101522;

  gap: 58px;

  color: #fff;
`;

export const PostInfoContainer = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;

  gap: 32px;
`;

export const PostHeader = styled.header`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
`;

export const LeftHeader = styled.div`
  display: flex;
  flex-direction: row;
  gap: 8px;
`;

export const PostTitle = styled.p`
  font-style: normal;
  font-weight: 700;
  font-size: 24px;
  line-height: 130%;

  @media (max-width: 768px) {
    font-size: 18px;
    text-align: justify;
  }
`;

export const PostFooter = styled.footer`
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  align-items: center;

  gap: 32px;

  @media (max-width: 768px) {
    flex-direction: column;
  }
`;

export const PostContent = styled.article`
  display: flex;
  flex-direction: column;
  width: 50%;
  height: auto;
  margin-bottom: 68px;

  font-size: 16px;

  gap: 16px;

  h3 {
    text-align: justify;
  }

  p {
    text-align: justify;
  }

  code {
    background-color: #3a536b;
    padding: 8px;
    border-radius: 5px;
    font-size: 105%;
  }

  @media (max-width: 768px) {
    width: 90%;
    font-size: 0.845rem;
  }
`;

export const Topics = styled.div`
  display: flex;
  flex-direction: row;
  gap: 8px;
`;
