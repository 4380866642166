import React from "react";

import { labels } from "../../labels/menu";
import SwitchButton from "../Switch";
import { NavItem, NavLinks , navProps } from "./styles";


export type globalPropsNav = navProps;

const NavItems: React.FC<globalPropsNav> = ({ scrollNav }) => {
  const { home, info } = true ? labels.ptBr : labels.en;

  return (
    <>
      <NavItem>
        <NavLinks
          scrollNav={scrollNav}
          to="heroSection"
          smooth={true}
          duration={300}
          spy={true}
          offset={-80}
        >
          {home}
        </NavLinks>
      </NavItem>
      <NavItem>
        <NavLinks
          scrollNav={scrollNav}
          to="info"
          smooth={true}
          duration={300}
          spy={true}
          offset={-80}
        >
          {info}
        </NavLinks>
      </NavItem>
      <NavItem>
        <SwitchButton />
      </NavItem>
    </>
  );
};

export default NavItems;
