import React /* { useContext } */ from "react";
import { useTheme } from "styled-components";

import { logo } from "../../labels/logo";
import { toggleHome } from "../../utils/functions";
import { logoProps,NavLogo, NavLogoDark  } from "./styles";

// import { ThemeContext } from "styled-components";

const Logo: React.FC<logoProps> = ({ scrollNav }) => {
  const { title } = useTheme();
  const { logoAsName } = true ? logo.ptBr : logo.en;

  return (
    <>
      {title === "light" ? (
        <NavLogo to="/" onClick={toggleHome} scrollNav={scrollNav}>
          {logoAsName && <h1>{logoAsName}</h1>}
        </NavLogo>
      ) : (
        <NavLogoDark to="/" onClick={toggleHome} scrollNav={scrollNav}>
          {logoAsName && <h1>{logoAsName}</h1>}
        </NavLogoDark>
      )}
    </>
  );
};

export default Logo;
