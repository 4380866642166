import styled from "styled-components";

export const Container = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  font-size: 3rem;
  padding: 16px;
  width: 100%;

  @media (max-width: 768px) {
    font-size: 1.2rem;
  }
`;

export const LabelContainer = styled.div`
  margin: 50px;
  width: 100%;

  @media (max-width: 768px) {
    margin: 50px;
    width: 100%;
  }
`;

export const Label = styled.p`
  text-align: center;
  color: #fff; // TOdo: change to theme

  @media (max-width: 768px) {
    font-size: 1rem;
  }
`;

export const SiteVersion = styled.text`
  text-align: right;
  font-size: 10px;
`;

export const TextContainer = styled.div`
  padding: 2px 5px;
`;

export const Link = styled.a`
display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;

  padding: 8px;

  //TODO: change to theme
  :visited {
    //color: ${(props) => props.theme.colors.text};
    color: #fff;
  }

  :active {
    // color: ${(props) => props.theme.colors.text};
    color: #fff;
  }

  :any-link {
    //color: ${(props) => props.theme.colors.text};
    color: #fff;
  }
`;