import styled from "styled-components";

export const Container = styled.div`
  width: 100%;
  height: 100%;

  display: flex;
  flex-direction: column;
  align-items: center;
  background: #101522;

  gap: 58px;

  @media (max-width: 768px) {
  }
`;
