import React from "react";
import { FaFacebook, FaInstagram, FaLinkedin,FaTwitter } from "react-icons/fa";

import { info } from "../../labels/info";
import { logo } from "../../labels/logo";
import { socialUrls } from "../../urls/social";
import { toggleHome } from "../../utils/functions";
import ImageContainer from "../ImageContainer";
import { data } from "./data";
import { labels } from "./labels";
import {
  Container,
  LinkItems,
  LinkS,
  LinksContainer,
  LinksWrapper,
  LinkTitle,
  ServerStatus,
  SiteVersion,
  Social,
  SocialIconLink,
  SocialIcons,
  SocialLogo,
  SocialWrapper,
  TextContainer,
  WebsiteRights,
  Wrapper,
} from "./styles";

const Footer: React.FC = () => {
  const { logoImageUrl } = logo.ptBr;
  const { copyright, about, terms, policy } = true ? labels.ptBr : labels.en;
  const { version } = true ? info.ptBr : info.en;
  const { facebook, twitter, instagram, linkedin } = socialUrls;
  // const [serverOn, setServerOn] = React.useState<boolean>(false);

  /* const getApi = async () => {
    try {
      const response = await estrngmsg.get("/");
      response.status === 200 ? setServerOn(true) : setServerOn(false);
      console.log(response.status);
    } catch (error) {
      console.log(error);
    }
  };

  useEffect(() => {
    getApi();
  }, []); */

  return (
    <Container>
      <Wrapper>
        <LinksContainer>
          <LinksWrapper>
            <LinkItems>
              <LinkTitle>{about}</LinkTitle>
              <LinkS to="/terms">{terms}</LinkS>
              <LinkS to="/policy">{policy}</LinkS>
            </LinkItems>
          </LinksWrapper>
        </LinksContainer>
        <Social>
          <SocialWrapper>
            <SocialLogo to="/" onClick={toggleHome}>
              <ImageContainer
                image_url={logoImageUrl}
                height="120"
                width="120"
              />
            </SocialLogo>
            <WebsiteRights>
              {copyright}
              {data.company} {data.year}
            </WebsiteRights>
            <SocialIcons>
              <SocialIconLink
                href={facebook}
                target="_blank"
                area-label="Facebook"
              >
                <FaFacebook />
              </SocialIconLink>
              <SocialIconLink
                href={instagram}
                target="_blank"
                area-label="Instagram"
              >
                <FaInstagram />
              </SocialIconLink>
              <SocialIconLink
                href={twitter}
                target="_blank"
                area-label="Twitter"
              >
                <FaTwitter />
              </SocialIconLink>
              <SocialIconLink
                href={linkedin}
                target="_blank"
                area-label="Linkedin"
              >
                <FaLinkedin />
              </SocialIconLink>
            </SocialIcons>
          </SocialWrapper>
        </Social>
        <a href="https://www.digitalocean.com/?refcode=92edefba5c1c&utm_campaign=Referral_Invite&utm_medium=Referral_Program&utm_source=badge">
          <img
            src="https://web-platforms.sfo2.cdn.digitaloceanspaces.com/WWW/Badge%201.svg"
            alt="DigitalOcean Referral Badge"
          />
        </a>
      </Wrapper>
      <SiteVersion>
        <TextContainer>
          <ServerStatus serverOn={true} />
          {version}
        </TextContainer>
      </SiteVersion>
    </Container>
  );
};

export default Footer;
// TODO: Arrumar um jeito de passar o valor inverso da cor do texto no footer
