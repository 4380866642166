import { createContext, PropsWithChildren, useContext } from "react";
import { DefaultTheme, ThemeProvider as StyledCompThemeProvider } from "styled-components";

import usePersistedState from "../../hooks/usePersistedState";
import GlobalStyle from "../../styles/global";
import dark from "../../styles/themes/dark";
import light from "../../styles/themes/light";

interface ToggleThemContextType {
  toggledTheme: () => void;
}

export const ToggleThemeContext = createContext<ToggleThemContextType>({} as ToggleThemContextType);

export const useToggleThemeContext = () => useContext(ToggleThemeContext);

export function ThemeProvider({ children }: PropsWithChildren) {
  const [theme, setTheme] = usePersistedState<DefaultTheme>("theme", dark);

  const toggledTheme = () => {
    setTheme(theme.title === "light" ? dark : light);
  };

  return (
    <ToggleThemeContext.Provider value={{ toggledTheme }}>
      <StyledCompThemeProvider theme={theme}>
        <GlobalStyle />
        {children}
      </StyledCompThemeProvider>
    </ToggleThemeContext.Provider>
  );
}

ThemeProvider.displayName = "ThemeProvider";
