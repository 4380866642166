import { MdArrowForward,MdKeyboardArrowRight } from "react-icons/md";
import { Link } from "react-router-dom";
import styled from "styled-components";

import light from "../../styles/themes/light";

type ButtonStyleProps = {
  primary?: boolean;
};

export const ButtonContainer = styled(Link)<ButtonStyleProps>`
  border-radius: 50px;
  background: ${light.colors.primary};
  white-space: nowrap;
  padding: ${(big) => (big ? "15px 48px" : "12px 30px")};
  color: ${(dark) => (dark ? "#010606" : "#fff")};
  font-size: ${(fontBig) => (fontBig ? "20px" : "16px")};
  outline: none;
  border: none;
  cursor: pointer;
  display: flex;
  justify-content: center;
  align-items: center;
  transition: all 0.2s ease-in-out;
  text-decoration: none;

  &:hover {
    transition: all 0.2s ease-in-out;
    background: ${(props) => props.theme.colors.quinary};
    color: ${(props) => props.theme.colors.primary};
  }
`;

export const ArrowForward = styled(MdArrowForward)`
  margin-left: 8px;
  font-size: 18px;
`;

export const ArrowRight = styled(MdKeyboardArrowRight)`
  margin-left: 8px;
  font-size: 20px;
`;
