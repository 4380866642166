import styled from "styled-components";

export const Container = styled.div`
  padding-top: 30px;
`;

// Icon container
export const IconContainer = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  height: 100%;
  width: 100%;
  font-size: 1.2rem;
  /* color: colors.primary, */
`;
