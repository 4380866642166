import React from "react";
import {
  ImFacebook2,
  ImGithub,
  ImInstagram,
  ImLinkedin,
  ImTwitch,
  ImTwitter,
} from "react-icons/im";

import { Container, Link } from "./styles";

interface Props {
  facebook: string;
  linkedin: string;
  instagram: string;
  twitter: string;
  github: string;
  twitch: string;
  pix: string;
  moreSocial?: [string, string];
}

type SocialProps = {
  urls: Props;
};

const Social: React.FC<SocialProps> = ({ urls }) => {
  return (
    <Container>
      <Link href={urls.github} target="_blank">
        <ImGithub />
      </Link>
      <Link href={urls.linkedin} target="_blank">
        <ImLinkedin />
      </Link>
      <Link href={urls.instagram} target="_blank">
        <ImInstagram />
      </Link>
      <Link href={urls.facebook} target="_blank">
        <ImFacebook2 />
      </Link>
      <Link href={urls.twitter} target="_blank">
        <ImTwitter />
      </Link>
      <Link href={urls.twitch} target="_blank">
        <ImTwitch />
      </Link>
    </Container>
  );
};

export default Social;
