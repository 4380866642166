import React from "react";

import Card from "../../components/Card";
import { cards } from "./data";
import { labels } from "./labels";
import { Container, Sh1, Wrapper } from "./styles";

const Products: React.FC = () => {
  const { sectionTitle } = true ? labels.ptBr : labels.en;

  return (
    <Container id="services">
      <Sh1>{sectionTitle}</Sh1>
      <Wrapper>
        {cards.map((card) => (
          <Card
            key={card.id}
            to={card.to}
            image={card.image}
            active={card.active}
            leng={true ? card.ptBr : card.en}
          />
        ))}
      </Wrapper>
    </Container>
  );
};

export default Products;
