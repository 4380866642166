import SwitchButton from "components/Switch";
import React from "react";

import { labels } from "../../labels/menu";
import { HeaderProps } from "../Header/index";
import {
  CloseIcon,
  Icon,
  SidebarContainer,
  SidebarLink,
  SidebarMenu,
  SidebarWrapper,
  SideBtnWrapper,
} from "./styles";

type SidebarProps = HeaderProps & {
  isOpen: boolean;
};

const Sidebar: React.FC<SidebarProps> = ({ isOpen, toggle }) => {
  const { home, info } = true ? labels.ptBr : labels.en;

  return (
    <SidebarContainer isOpen={isOpen} onClick={toggle}>
      <Icon onClick={toggle}>
        <CloseIcon />
      </Icon>
      <SidebarWrapper>
        <SidebarMenu>
          <SidebarLink to="heroSection" onClick={toggle}>
            {home}
          </SidebarLink>
          <SidebarLink to="info" onClick={toggle}>
            {info}
          </SidebarLink>
        </SidebarMenu>
        <SideBtnWrapper>
          <SwitchButton />
        </SideBtnWrapper>
      </SidebarWrapper>
    </SidebarContainer>
  );
};

export default Sidebar;
