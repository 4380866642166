import { useToggleThemeContext } from "contexts/ThemeContext";
import { BsMoon , BsSun } from "react-icons/bs";
import Switch from "react-switch";
import { useTheme } from "styled-components";

import { Container, IconContainer } from "./styles";

const SwitchButton = () => {
  const { toggledTheme } = useToggleThemeContext();
  const { title } = useTheme();

  return (
    <Container>
      <Switch
        onChange={toggledTheme}
        checked={title === "dark"}
        checkedIcon={
          <IconContainer>
            <BsSun size={30} color="#ffc222" />
          </IconContainer>
        }
        uncheckedIcon={
          <IconContainer>
            <BsMoon size={30} color="#191970" />
          </IconContainer>
        }
        height={20}
        width={30}
        handleDiameter={1}
        offColor="transparent"
        onColor="transparent"
      />
    </Container>
  );
};

export default SwitchButton;
