import styled from "styled-components";

export const Container = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: center;
  padding: 16px;
`;

export const Link = styled.a`
  padding: 16px;
  text-decoration: none;

  //TODO: change to theme
  :visited {
    //color: ${(props) => props.theme.colors.text};
    color: #fff;
  }

  :active {
    // color: ${(props) => props.theme.colors.text};
    color: #fff;
  }

  :any-link {
    //color: ${(props) => props.theme.colors.text};
    color: #fff;
  }
`;
