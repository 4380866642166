export const labels = {
  ptBr: {
    legend: "Entre em contado",
    placeholderName: "Seu nome",
    placeholderEmail: "Seu e-mail",
    placeholderMessage: "Sua mensagem",
    bottonTitle: "Enviar",
    loadingFatch: "Enviando...",
  },
  en: {
    legend: "Contact us",
    placeholderName: "Your name",
    placeholderEmail: "Your e-mail",
    placeholderMessage: "Your message",
    bottonTitle: "Send",
    loadingFatch: "Sending...",
  },
  es: {
    legend: "Contáctenos",
    placeholderName: "Su nombre",
    placeholderEmail: "Su correo electrónico",
    placeholderMessage: "Su mensaje",
    bottonTitle: "Enviar",
    loadingFatch: "Enviando...",
  },
  jp: {
    legend: "お問い合わせ",
    placeholderName: "お名前",
    placeholderEmail: "メールアドレス",
    placeholderMessage: "お問い合わせ内容",
    bottonTitle: "送信",
    loadingFatch: "送信中...",
  },
};
