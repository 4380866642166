import React, { useState } from "react";

import { ArrowForward, ArrowRight, ButtonContainer } from "./styles";

type ButtonProps = {
  to: string;
  buttonTitle: string;
  primary: boolean;
  big: boolean;
  dark: boolean;
  fontBig: boolean;
  smooth?: boolean;
  duration?: number;
  spy?: boolean;
  exact?: boolean;
  offset?: number;
};

export const Button: React.FC<ButtonProps> = ({ buttonTitle, ...rest }) => {
  const [hover, setHover] = useState(false);

  const onHover = () => {
    setHover(!hover);
  };

  return (
    <ButtonContainer {...rest} onMouseEnter={onHover} onMouseLeave={onHover}>
      {buttonTitle} {hover ? <ArrowForward /> : <ArrowRight />}
    </ButtonContainer>
  );
};

export default Button;
