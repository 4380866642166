export const socialUrls = {
  facebook: `https://facebook.com/${process.env.REACT_APP_FB}`,
  linkedin: `https://linkedin.com/in/${process.env.REACT_APP_LINKEDIN}`,
  instagram: `https://instagram.com/${process.env.REACT_APP_INSTAGRAM}`,
  twitter: `https://twitter.com/${process.env.REACT_APP_TWITTER}`,
  github: `https://github.com/${process.env.REACT_APP_GITHUB}`,
  twitch: `https://www.twitch.tv/${process.env.REACT_APP_TWITCH}`,
  pix: 'https://livepix.gg/estrng',

  githubUser: "estrng",
};
//TODO: pensar em uma maneira de cadastro dessas informações
