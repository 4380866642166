import React from "react";

import { ImgContainer } from "./styles";

type Props = {
  image_url: string;
  width?: string;
  height?: string;
};

const ImageContainer: React.FC<Props> = ({ image_url, width, height }) => {
  return <ImgContainer src={image_url} height={height} width={width} />;
};

export default ImageContainer;
