import { FaBars } from "react-icons/fa";

import { Container } from "./styles";

type MobileButtonProps = {
  onClick(): void;
  scrollNav: boolean;
};

const MobileIcon: React.FC<MobileButtonProps> = ({ onClick, scrollNav }) => {
  return (
    <Container onClick={onClick}>
      {scrollNav ? <FaBars color="#000" /> : <FaBars color="#FFF" />}
    </Container>
  );
};

export default MobileIcon;
