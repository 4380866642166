import styled from "styled-components";

type SubtitleProps = {
  darkText: boolean;
};

type ContainerProps = {
  lightBg: boolean;
};

type RowProps = {
  imgStart: boolean;
};

type HeadingProps = {
  lightText: boolean;
};

export const Container = styled.div<ContainerProps>`
  color: #fff;
  background: ${(props) => props.theme.colors.primary};
  height: auto;

  @media (max-width: 768px) {
    height: auto;
  }

  @media (max-width: 926px) {
    height: auto;
  }
`;

export const Wrapper = styled.div`
  display: grid;
  z-index: 1;
  height: 100%;
  width: 100%;
  max-width: 1100px;
  margin-right: auto;
  margin-left: auto;
  padding-top: 0 24px;
  justify-content: center;

  @media screen and (max-width: 768) {
    height: 860px;
    width: 100%;
  }
`;

export const Row = styled.div<RowProps>`
  display: flex;
  flex-direction: ${({ imgStart }) => (imgStart ? "row-reverse" : "row")};
  align-items: center;
  padding-top: 48px;

  @media screen and (max-width: 768px) {
    flex-direction: ${({ imgStart }) => (imgStart ? "row-reverse" : "column")};
    height: auto;
  }
`;

export const Column = styled.div`
  margin-bottom: 15px;
  padding: 0 15px;
  grid-area: "col1";
`;

export const Column2 = styled.div`
  margin-bottom: 15px;
  padding: 0 15px;
  grid-area: "col2";
`;

export const TextWrapper = styled.div`
  max-width: 540px;
  padding-top: 0;
  padding-bottom: 60px;
`;

export const TopLine = styled.p`
  color: #01bf71;
  font-size: 16px;
  line-height: 16px;
  font-weight: 700;
  letter-spacing: 1.4px;
  text-transform: uppercase;
  margin-bottom: 16px;
`;

export const Heading = styled.h1<HeadingProps>`
  margin-bottom: 24px;
  font-size: 18px;
  line-height: 1;
  font-weight: 600;
  color: ${(props) => props.theme.colors.text};

  @media screen and (max-width: 480px) {
    font-size: 16px;
  }
`;

export const Subtitle = styled.p<SubtitleProps>`
  max-width: 540px;
  margin-bottom: 35px;
  font-size: 16px;
  line-height: 24px;
  color: ${(props) => props.theme.colors.text};
`;

export const ButtonWrapper = styled.div`
  display: flex;
  justify-content: flex-start;
`;

export const ImageWrapper = styled.div`
  max-width: 555px;
  height: 100%;
`;

export const Image = styled.img`
  width: 100%;
  margin: 0 0 10px 0;
  padding-right: 0;
`;
