export const homeObjOne = {
  id: "info",
  lightBg: true,
  lightText: false,
  lightTextDsc: true,
  topLine: "",
  headline: "Construa uma solução na internet para sua empresa.",
  description:
    "Entrando em contato por este site e você ganha um desconto especial no primeiro projeto.",
  buttonLabel: "Bora!",
  imgStart: false,
  img: require("../../images/building.svg"),
  alt: "Car",
  dark: true,
  primary: true,
  darkText: false,
  dark2: false,
  smooth: false,
};

export const homeObjTwo = {
  id: "discover",
  lightBg: true,
  lightText: false,
  lightTextDsc: true,
  topLine: "Premium Bank",
  headline: "Unlimited Transactions with zero fees",
  description:
    "Get your early transaction for this year to send us the new object for any durection",
  buttonLabel: "Bora!",
  imgStart: true,
  img: require("../../images/building.svg"),
  alt: "Car",
  dark: true,
  primary: true,
  darkText: false,
  dark2: false,
  smooth: false,
};

export const homeObjThree = {
  id: "compra",
  lightBg: true,
  lightText: false,
  lightTextDsc: true,
  topLine: "Premium Bank",
  headline: "Unlimited Transactions with zero fees",
  description:
    "Get your early transaction for this year to send us the new object for any durection",
  buttonLabel: "Bora!",
  imgStart: false,
  img: require("../../images/building.svg"),
  alt: "Car",
  dark: true,
  primary: true,
  darkText: false,
  dark2: false,
  smooth: false,
};
