import { Link } from "react-router-dom";
import styled from "styled-components";

export const Container = styled.div`
  //text;
  text-align: center;
`;

export const LinkS = styled(Link)`
  color: ${(props) =>
    true
      ? props.theme.colors.text
      : "#fff"}; // TODO: change to theme.colors.text
  text-decoration: none;
  margin-bottom: 0.5rem;
  font-size: 14px;

  &:hover {
    color: #01bf71;
    transition: 0.3s ease-out;
  }
`;
