import { ThemeProvider } from "contexts/ThemeContext";
import { BlogDefaultLayout } from "layouts";
import { Route, Routes as ReactRoutes } from "react-router-dom";

import { Pages } from "../pages";

const Routes: React.FC = () => {
  return (
    <ThemeProvider>
      <ReactRoutes>
        <Route path="/" element={<Pages.Home />} index />
        <Route path="/signin" element={<Pages.SignIn />} />
        <Route path="/terms" element={<Pages.Terms />} />
        <Route path="/investor" element={<Pages.Investor />} />
        <Route path="/policy" element={<Pages.Policy />} />
        <Route path="/landing" element={<Pages.Landing />} />

        <Route path="blog" element={<BlogDefaultLayout />}>
          <Route index element={<Pages.Blog />} />
          <Route path="main" element={<Pages.Blog />} />
          <Route path="post/:post_id" element={<Pages.Post />} />
        </Route>
      </ReactRoutes>
    </ThemeProvider>
  );
};

export default Routes;
