import { useEffect,useState } from "react";
import { IconContext } from "react-icons/lib";

import Logo from "../Logo";
import Menu from "../Menu";
import MobileIcon from "../MobileIcon";
import { Nav, NavContainer } from "./styles";

export type HeaderProps = {
  toggle(): void;
};

const Header: React.FC<HeaderProps> = ({ toggle }) => {
  const [scrollNav, setScrollNav] = useState(false);

  const handleScroll = () => {
    if (window.scrollY >= 80) setScrollNav(true);
    else setScrollNav(false);
  };

  useEffect(() => {
    window.addEventListener("scroll", handleScroll);
  }, []);

  return (
    <>
      <IconContext.Provider value={{ color: "#fff" }}>
        <Nav scrollnav={scrollNav}>
          <NavContainer>
            <Logo scrollNav={scrollNav} />
            <Menu scrollNav={scrollNav} />
            <MobileIcon scrollNav={scrollNav} onClick={toggle} />
          </NavContainer>
        </Nav>
      </IconContext.Provider>
    </>
  );
};

export default Header;
