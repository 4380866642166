import styled from "styled-components";

export const Container = styled.a`
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  text-decoration: none;
  gap: 4px;

  cursor: pointer;
`;

export const Label = styled.p`
  color: #3294f8;

  @media (max-width: 768px) {
    font-size: 0.7rem;
  }
`;

export const Icon = styled.img`
  width: 12px;
  height: 12px;
`;
