import React from "react";

import cover from "../../assets/cover-header.svg";
import ImageContainer from "../ImageContainer";
import { Container } from "./styles";

const BlogCoverHeader: React.FC = () => {
  return (
    <Container>
      <img src={cover} alt="cover-in-header" />
      <div>
        <ImageContainer
          image_url="https://i.imgur.com/lQiLVfW.png"
          width="120"
          height="120"
        />
        <p>BEM VINDOS!</p>
      </div>
    </Container>
  );
};

export default BlogCoverHeader;
