export const labels = {
  ptBr: {
    greetings: "Em breve!",
    bigPhrase: `José Ivan R. de Oliveira`,
    launch: "Lançamento",
    rightsPragraph: `Todos os diretos reservados a José Ivan Ribeiro de Oliveira`,
  },

  en: {
    greetings: "Come soon!",
    bigPhrase:
      "Hello visitor, my name is José Ivan, I am JavaScript Developer, I also have technical training in administration and I am building this site to better serve you.",
    launch: "Realese",
    rightsPragraph: `All rights reserved to José Ivan Ribeiro de Oliveira`,
  },
};
