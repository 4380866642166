import styled from "styled-components";

export const Container = styled.div`
  background: ${(props) => props.color};
  opacity: 0.8;
  border-radius: 50px;
  padding: 4px 4px;

  font-size: 12px;
`;
