import React, { AnchorHTMLAttributes } from "react";

import link from "../../assets/link.svg";
import { Container, Icon,Label } from "./styles";

interface LabeLinkProps extends AnchorHTMLAttributes<HTMLAnchorElement> {
  label: string;
  icon?: boolean;
}

export const LabelLink: React.FC<LabeLinkProps> = ({
  label,
  icon = false,
  ...props
}) => {
  return (
    <Container {...props}>
      <Label>{label}</Label>
      {icon && <Icon src={link} />}
    </Container>
  );
};
