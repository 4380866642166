import { DefaultTheme } from "styled-components";

const dark = {
  title: "dark",
  colors: {
    primary: "#000000",
    secondary: "#303030",
    ternary: "#5E5E5E",
    quaternary: "#919191",
    quinary: "#C6C6C6",

    background: "#000000",
    text: "#fff",
    main_text: "#fff",
    blog_background: "#071422",
  },
} as DefaultTheme;

export default dark;
