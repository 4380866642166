import styled from "styled-components";

export const Container = styled.div`
  width: 100%;
  height: 100%;

  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: space-between;

  background: ${(props) => props.theme.colors.blog_background};

  @media (max-width: 768px) {
    gap: 27px;
  }
`;
