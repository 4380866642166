import { DefaultTheme } from "styled-components";

const light = {
  title: "light",
  colors: {
    primary: "#f9f9f9",
    secondary: "#C6C6C6",
    ternary: "#919191",
    quaternary: "#5E5E5E",
    quinary: "#303030",

    background: "#f9f9f9",
    text: "#000",
    main_text: "#fff",
    blog_background: "#071422",
  },
} as DefaultTheme;

export default light;
