import React from "react";
import Personal from "sections/Personal";

import Video from "../../videos/video.mp4";
import { Background, Container, Content, VideoBg } from "./styles";

const Hero: React.FC = () => {
  return (
    <Container id="heroSection">
      <Background>
        <VideoBg autoPlay loop muted disablePictureInPicture>
          <source src={Video} type="video/mp4" />
          <source src={Video} type="video/webm" />
        </VideoBg>
      </Background>
      <Content>
        <Personal />
      </Content>
    </Container>
  );
};

export default Hero;
