import ImageContainer from "components/ImageContainer";
import { useGithubUserData } from "hooks/useGithubUserData";
import React from "react";
import {
  ImMail4,
} from "react-icons/im";

import Button from "../../components/Button";
import Social from "../../components/Social";
import { socialUrls } from "../../urls/social";
import { labels } from "./labels";
import { Container, Label, LabelContainer, Link } from "./styles";

const Personal: React.FC = () => {
  const label = true ? labels.ptBr : labels.en;
  const { gitHubData } = useGithubUserData();

  return (
    <Container id="personal">
      <ImageContainer image_url={gitHubData.avatar_url} width="320" height="320" />
      <LabelContainer>
        <Label>{label.bigPhrase}</Label>
        <Label>{"Desenvolvedor JavaScript"}</Label>
      </LabelContainer>
      <div style={{
        display: "flex",
        flexDirection: "row",
        justifyContent: "center",
        alignItems: "center",
      }}>

      <h6 style={{
        color: "#fff",
        
      }}>{`Me envie uma mensagem 👉🏻 `}</h6>
      <Link href={socialUrls.pix} target="_blank">
        <ImMail4 />
      </Link>
      </div>
      <Social urls={socialUrls} />
      
      
      <Button buttonTitle="Confira meu blog" to="blog" big fontBig dark primary />
    </Container>
  );
};

export default Personal;
