import React from "react";

import ScrollToTop from "../../utils/ScrollToTop";
import {
  Button,
  Container,
  Content,
  Form,
  H1,
  Icon,
  Input,
  Label,
  Text,
  Wrapper,
} from "./styles";

const SignIn: React.FC = () => {
  return (
    <>
      <ScrollToTop />
      <Container>
        <Wrapper>
          <Icon to="/">estrng</Icon>
          <Content>
            <Form>
              <H1>Sign In</H1>
              <Label htmlFor="for">Email</Label>
              <Input
                type="email"
                /* require */ placeholder="name@example.com"
              />
              <Label htmlFor="for">Password</Label>
              <Input type="password" /* require */ placeholder="********" />
              <Button type="submit">Sign In</Button>
              <Text>esqueci a senha</Text>
            </Form>
          </Content>
        </Wrapper>
      </Container>
    </>
  );
};

export default SignIn;
