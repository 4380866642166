import Sidebar from "components/Sidebar";

import Form from "../../sections/Form";
import Hero from "../../sections/Hero";
import Info from "../../sections/Info";
import Personal from "../../sections/Personal";
import Products from "../../sections/Products";

const homeImports = { Personal, Sidebar, Hero, Products, Info, Form };

export default homeImports;
