import Blog from "./Blog";
import Home from "./Home";
import Investor from "./Investor";
import Landing from "./Landing";
import Policy from "./Policy";
import Post from "./Post";
import SignIn from "./SignIn";
import Terms from "./Terms";

export const Pages = {
  Home,
  SignIn,
  Terms,
  Investor,
  Policy,
  Landing,
  Blog,
  Post,
};
