import styled from "styled-components";

type navProps = {
  scrollnav: boolean;
};

export const Nav = styled.nav<navProps>`
  /* background: ${(props) => props.theme.colors.secondary}; */
  background: ${(props) =>
    props.scrollnav ? props.theme.colors.primary : "transparent"};
  /* background: transparent; */
  margin-top: -80px;
  height: 80px;
  width: auto;
  color: #fff;
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 0 20px;
  font-size: 1rem;
  position: sticky;
  top: 0;
  z-index: 10;

  @media screen and (max-width: 960px) {
    transition: 0.8 all ease;
  }
`;

export const NavContainer = styled.div`
  display: flex;
  justify-content: space-between;
  height: 80px;
  z-index: 1;
  width: 100%;
  padding: 0 24px;
`;

export const ButtonWrapper = styled.div`
  margin-top: 40px;
  display: flex;
  flex-direction: column;
  align-items: center;

  @media screen and (max-width: 768px) {
    display: none;
  }
`;
