export const labels = {
  ptBr: {
    home: "Home",
    info: "Informação",
    presentation: "Apresentação",
    solutions: "Soluções",
  },
  en: {
    home: "Home",
    info: "Info",
    products: "Products",
    presentation: "Presentation",
    solutions: "Sobre",
  },
};
