import { createGlobalStyle } from "styled-components";

export default createGlobalStyle`

*{
  margin: 0;
  padding: 0;
  box-sizing: border-box;
}

body {
  background: ${(props) => props.theme.colors.background};
  font-size: 14px;
  color: ${(props) => props.theme.colors.text};
  font-family: 'Fira Code', monospace;

  // media query for mobile font size
  @media (max-width: 768px) {
    font-size: 0.438rem;
  }
}

body::-webkit-scrollbar {
  width: 3px;
  border-radius: 50px;
  /* display: none; */
}

body::-webkit-scrollbar-thumb {
  //background-color: ${(props) => props.theme.colors.text};
  background-color: #071422;
  border-radius: 50px;
  size: 10px;
}
`;
