export const labels = {
  ptBr: {
    copyright: "Todos os direitos reservados a ",
    about: "Sobre nós",
    terms: "Termos de uso",
    investor: "Investidores",
    testimonies: "Testemunhos",
    policy: "Política de privacidade",
  },
  en: {
    copyright: "All rights reserved to ",
    about: "About us",
    terms: "Terms of use",
    investor: "Investors",
    testimonies: "Testimonies",
    policy: "Privacy policy",
  },
};
