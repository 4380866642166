import { Link } from "react-router-dom";
import styled from "styled-components";

export type StyleProps = {
  active: boolean;
};

export const Container = styled(Link)<StyleProps>`
  background: ${(props) => props.theme.colors.primary};
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  align-items: center;
  border-radius: 10px;
  height: 480px;
  width: 320px;
  padding: 30px;
  box-shadow: 0px 1px 3px rgba(0, 0, 0, 0.2);
  transition: all 0.2s ease-in-out;
  opacity: ${({ active }) => (active ? 1 : 0.5)};
  text-decoration: none;
  color: ${(props) => props.theme.colors.text};

  &:hover {
    transform: scale(1.02);
    transition: all 0.2s ease-in-out;
    cursor: ${({ active }) => (active ? "pointer" : "default")};
  }
`;

export const Title = styled.h2`
  font-size: 1.5rem;
  margin-bottom: 10px;
`;

export const TitleGo = styled.h2`
  color: green;
  font-size: 1.5rem;
  /* margin-bottom: 10px; */
`;

export const BottomTittle = styled.h2<StyleProps>`
  opacity: ${({ active }) => (active ? 0 : 0.3)};
  font-size: 1.5rem;
  color: red;
  /* margin-bottom: 10px; */
`;

export const Paragraph = styled.p`
  font-size: 1.5rem;
  text-align: center;
  color: ${(props) => props.theme.colors.text};
`;

export const Icon = styled.img`
  height: 160px;
  width: 160px;
  padding: -20px;
`;
