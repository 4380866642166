import { useTheme } from "styled-components";

import NavItems from "../NavItems";
import { globalPropsNav } from "../NavItems/index";
import NavLinksDark from "../NavLinksDark";
import { NavMenu } from "./styles";

const Menu: React.FC<globalPropsNav> = ({ scrollNav }) => {
  const { title } = useTheme();

  return (
    <NavMenu>
      {title === "light" ? (
        <NavItems scrollNav={scrollNav} />
      ) : (
        <NavLinksDark scrollNav={scrollNav} />
      )}
    </NavMenu>
  );
};

export default Menu;
