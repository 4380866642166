import Footer from "components/Footer";
import Form from "sections/Form";

import Video from "../../videos/video2.mp4";
import { labels } from "./labels";
import * as L from "./styles";

const Landing: React.FC = () => {
  const { whatIs } = true ? labels.ptBr : labels.en;

  return (
    <>
      <L.Container>
        <L.Background>
          <L.VideoBg autoPlay loop muted disablePictureInPicture>
            <source src={Video} type="video/mp4" />
            <source src={Video} type="video/webm" />
          </L.VideoBg>
        </L.Background>
        <L.Content>
          <L.BigTitle>
            Tenha uma ótima pagina de apresetação para sua empresa
          </L.BigTitle>
        </L.Content>
      </L.Container>
      <L.ApresentationSection>
        <L.TextContainer>
          <L.Title>Landing</L.Title>
          <p>{whatIs}</p>
        </L.TextContainer>
      </L.ApresentationSection>
      <Form />
      <L.ArrowLeftLink to="/">
        <L.Title>Voltar</L.Title>
      </L.ArrowLeftLink>
      <Footer />
    </>
  );
};

export default Landing;
