import React, { FormEvent } from "react";
import toast, { Toaster } from "react-hot-toast";
import * as Yup from "yup";

import messageApi from "../../services/apis/messageApi";
import { labels } from "./labels";
import {
  Button,
  ContactForm,
  Fieldset,
  FormSection,
  Input,
  InputBlock,
  Label,
  Legend,
  Textarea,
} from "./styles";

const schema = Yup.object().shape({
  name: Yup.string().required("Nome é obrigatório"),
  email: Yup.string()
    .email("Deve ser uma email válido!")
    .required("Precisamos de uma email, por favor."),
  text: Yup.string()
    .min(1, "Acho que pelo menos uma palavra seria ótimo.")
    .required("Uma palavra seria ótimo!"),
});

const Form: React.FC = () => {
  const {
    legend,
    placeholderName,
    placeholderEmail,
    placeholderMessage,
    bottonTitle,
    loadingFatch,
  } = true ? labels.ptBr : labels.en;

  const [name, setName] = React.useState<string>();
  const [email, setEmail] = React.useState<string>();
  const [text, setText] = React.useState<string>();
  const [loading, setLoading] = React.useState(false);

  async function handleSendMessage(e: FormEvent<HTMLFormElement>) {
    e.preventDefault();
    setLoading(true);
    await schema.isValid({ name, email, text }).then((valid) => {
      valid ? sendMessageApi(e) : errorMessage();
    });
  }

  function errorMessage() {
    toast.error("Preencha todos os campos!");
    setLoading(false);
  }

  async function sendMessageApi(e: FormEvent<HTMLFormElement>) {
    e.preventDefault();
    try {
      await messageApi.post("messages", { name, email, text });
      toast.success("Mensagem enviada com sucesso!");
      setLoading(false);
      clear(e);
      // TODO: talvez colocar o log aqui.
    } catch (error) {
      toast.error("Mensagem não enviada, tente novamente");
      setLoading(false);
      clear(e);
    }
  }

  async function clear(e: FormEvent) {
    const { target } = e;
    const form = target as HTMLFormElement;
    form.reset();
  }

  return (
    <FormSection>
      <ContactForm onSubmit={handleSendMessage}>
        <Fieldset>
          <Legend>{legend}</Legend>
          <InputBlock>
            <Label htmlFor="name">
              <Input
                id="name"
                type="name"
                placeholder={placeholderName}
                onChange={(e) => {
                  setName(e.target.value);
                }}
              />
            </Label>
          </InputBlock>
          <InputBlock>
            <Label htmlFor="email">
              <Input
                id="email"
                type="email"
                placeholder={placeholderEmail}
                onChange={(e) => {
                  setEmail(e.target.value);
                }}
              />
            </Label>
          </InputBlock>
          <InputBlock>
            <Label htmlFor="name">
              <Textarea
                id="name"
                maxLength={300}
                placeholder={placeholderMessage}
                onChange={(e) => {
                  setText(e.target.value);
                }}
              />
            </Label>
          </InputBlock>
        </Fieldset>
        <Button type="submit" disabled={loading}>
          {loading ? loadingFatch : bottonTitle}
        </Button>
      </ContactForm>
      <Toaster
        position="bottom-center"
        reverseOrder={false}
        toastOptions={{ duration: 5000 }}
      />
    </FormSection>
  );
};

export default Form;
