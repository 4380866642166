import { labels } from "./labels";
import { BottomTittle, Container, Icon, Paragraph, StyleProps, Title, TitleGo } from "./styles";

export type CardProps = StyleProps & {
  to: string;
  image: string;
  leng: {
    cardTitle: string;
    smallText: string;
    explantion: string;
  };
};

const Card: React.FC<CardProps> = ({ leng, active, image, to, ...rest }) => {
  const { bottomText } = true ? labels.ptBr : labels.en;

  return (
    <>
      {active ? (
        <Container to={"/" + to} active={active} {...rest}>
          <Icon src={image} />
          <Title>{leng.cardTitle}</Title>
          <Paragraph>{leng.smallText}</Paragraph>
          <BottomTittle active={active}>{bottomText}</BottomTittle>
          <TitleGo>SAIBA MAIS!</TitleGo>
        </Container>
      ) : (
        <Container
          to={"/" + to}
          active={active}
          onClick={(e) => {
            e.preventDefault();
          }}
          {...rest}
        >
          <Icon src={image} />
          <Title>{leng.cardTitle}</Title>
          <Paragraph>{leng.smallText}</Paragraph>
          <BottomTittle active={active}>{bottomText}</BottomTittle>
        </Container>
      )}
    </>
  );
};

export default Card;
