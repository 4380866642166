import { Link } from "react-router-dom";
import styled from "styled-components";

export const Container = styled.footer`
  width: 100%;
  background-color: #101522;
`;

export const Wrapper = styled.div`
  padding: 48px 24px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  max-width: 1100px;
  margin: 0 auto;
`;

export const LinksContainer = styled.div`
  display: flex;
  justify-content: center;

  @media screen and (max-width: 820px) {
    padding-top: 32px;
  }
`;

export const LinksWrapper = styled.div`
  display: flex;

  @media screen and (max-width: 820px) {
    flex-direction: column;
  }
`;

export const LinkItems = styled.div`
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  margin: 16px;
  text-align: left;
  width: 160px;
  box-sizing: border-box;
  color: #fff;

  @media screen and (max-width: 768px) {
    margin: 0;
    padding: 10px;
    width: 100%;
    align-items: center;
  } // TODO: aplicar cor dinamica para o texto
`;

export const LinkTitle = styled.h1`
  font-size: 14px;
  margin-bottom: 16px;
`;

export const LinkS = styled(Link)`
  color: ${(props) =>
    !true
      ? props.theme.colors.text
      : "#fff"}; // TODO: change to theme.colors.text
  text-decoration: none;
  margin-bottom: 0.5rem;
  font-size: 14px;

  &:hover {
    color: #01bf71;
    transition: 0.3s ease-out;
  }
`;

export const Social = styled.section`
  max-width: 1000px;
  width: 100%;
`;

export const SocialWrapper = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  max-width: 1100px;
  margin: 40 auto 0 auto;

  @media screen and (max-width: 820px) {
    align-items: center;
    flex-direction: column;
  }
`;

export const SocialLogo = styled(Link)`
  color: #fff;
  justify-content: start;
  cursor: pointer;
  text-decoration: none;
  font-size: 1.5rem;
  display: flex;
  align-items: center;
  margin-bottom: 16px;
  font-weight: bold;
`;

export const WebsiteRights = styled.small`
  display: flex;
  color: #fff;
  margin-bottom: 16px;
  font-size: 0.9rem;

  @media screen and (max-width: 768px) {
    text-align: center;
    margin-bottom: 32px;
    display: flex;
    justify-content: center;
    align-items: center;
  }
`;

export const SocialIcons = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 16px;
  width: 240px;
`;

export const SocialIconLink = styled.a`
  color: #fff;
  font-size: 24px;
`;

export const SiteVersion = styled.text`
  text-align: right;
  font-size: 10px;

  @media screen and (max-width: 420px) {
    text-align: center;
  }
`;

export const TextContainer = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: right;
  align-items: center;

  padding: 2px 5px;
  color: #fff;
`;

type ServerStatusProps = {
  serverOn: boolean;
};

export const ServerStatus = styled.div<ServerStatusProps>`
  width: 5px;
  height: 5px;
  border-radius: 50%;
  background-color: ${(props) => (props.serverOn ? "#01bf71" : "#ff0000")};
  margin-right: 5px;
  margin-top: 2px;
`;
