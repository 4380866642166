import React from "react";

import Building from "../../images/building.svg";
import { Column, Column2, Container, Heading, Image, ImageWrapper, Row, Subtitle, TextWrapper, TopLine, Wrapper } from "./styles";

type Props = {
  id: string;
  lightBg: boolean;
  imgStart: boolean;
  topLine: string;
  lightText: boolean;
  headline: string;
  darkText: boolean;
  dark: boolean;
  description: string;
  buttonLabel: string;
  img: string;
  alt: string;
  primary: boolean;
};

const Info: React.FC<Props> = ({ lightBg, imgStart, lightText, darkText, alt, id }) => {
  return (
    <Container lightBg={lightBg} id={id}>
      <Wrapper>
        <Row imgStart={imgStart}>
          <Column>
            <TextWrapper>
              <TopLine>Skills</TopLine>
              <Heading lightText={lightText}>Desenvolvedor JavaScript.</Heading>
              <Heading lightText={lightText}>Conhecimentos avançados em desenvolvimento Typescript/Javascript.</Heading>
              <Heading lightText={lightText}>Frameworks: NextJS, NestJS, Vite, Create React App.</Heading>
              <Heading lightText={lightText}>
                Banco de dados: SQL Server, MySQL, SQLite, PostgreSQL e similares, análise e modelagem.
                <Heading lightText={lightText}>NoSQL: Mongo e Firebase</Heading>
              </Heading>
              <Heading lightText={lightText}>Linguagens extras: C#, Python.</Heading>
              <Heading lightText={lightText}>OMRs: Prisma, Sequelize, TypeORM, mongoose e similares.</Heading>
              <Heading lightText={lightText}>
                Sólidos Conhecimentos em testes automatizados front e back-end com jest, react-test-library
              </Heading>
              <TopLine>Outros conhecimentos</TopLine>
              <Subtitle darkText={darkText}>
                SCRUM, Kanban - Ferramentas Ágeis tais como Jira, Confluence, Trello e notion. - Figma - Atuação em projeto de
                Design System. - GitHub e GitLab: gerenciamento de branchs e processos de deployment
              </Subtitle>
            </TextWrapper>
          </Column>
          <Column2>
            <ImageWrapper>
              <Image src={Building} alt={alt} />
            </ImageWrapper>
          </Column2>
        </Row>
      </Wrapper>
    </Container>
  );
};

export default Info;
