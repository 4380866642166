import styled from "styled-components";

export const Container = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  width: 100%;

  color: ${(props) => props.theme.colors.main_text};

  img {
    width: 100%;
    height: 100%;
  }

  div {
    display: flex;
    flex-direction: column;
    width: 120px;
    height: 120px;
    position: absolute;
    top: 10%;
    left: 50%;
    transform: translate(-50%, -50%);

    align-items: center;
    gap: 24px;
  }

  @media (max-width: 768px) {
    width: 100vw;
    margin-bottom: 180px;

    img {
      width: 100%;
      height: 100%;
    }

    div {
    }
  }
`;
