import { Link as LinkS } from "react-scroll";
import styled from "styled-components";

import dark from "../../styles/themes/dark";

export type navProps = {
  scrollNav: boolean;
};

export const NavItem = styled.li`
  height: 80px;
`;

export const NavLinksDark = styled(LinkS)<navProps>`
  color: ${(props) => (props.scrollNav ? dark.colors.text : dark.colors.text)};
  display: flex;
  align-items: center;
  text-decoration: none;
  padding: 0 1rem;
  height: 100%;
  cursor: pointer;

  &.active {
    border-bottom: 3px solid ${(props) => props.theme.colors.quinary};
  }
`;
