import styled from "styled-components";

export const FormSection = styled.section`
  border: 0;
  /* border-top: 1px solid #ccc; */
  /* border-bottom: 1px solid #ccc; */
`;

export const ContactForm = styled.form`
  width: 700px;
  margin: 64px auto;

  background: #101522;
  border: 1px solid ${(props) => props.theme.colors.primary};
  border-radius: 20px;
  border-bottom: 1px solid #101522;

  padding: 64px 80px;

  overflow: hidden;
  box-shadow: 4px 2px 8px rgba(0, 0, 0, 0.5);

  &:hover {
    transform: scale(1.05);
    transition: all 0.2s ease-in-out;
  }

  @media (max-width: 768px) {
    width: 95%;
    padding: 32px;
  }
`;

export const Fieldset = styled.fieldset`
  display: flex;
  flex-direction: column;
  margin-bottom: 32px;

  border: 0;
  margin-top: 10px;
`;

export const Legend = styled.legend`
  width: 100%;
  align-items: center;
  font-size: 32px;
  line-height: 34px;
  color: #fff;
  font-weight: 700;

  border-bottom: 1px solid #d3e2e5;
  margin-bottom: 40px;
  padding-bottom: 24px;

  @media (max-width: 768px) {
    font-size: 24px;
    line-height: 28px;
  }
`;

export const InputBlock = styled.div`
  margin-top: 24px;
`;

export const Label = styled.label`
  display: flex;
  color: #8fa7b3;
  margin-bottom: 8px;
  line-height: 24px;
`;

export const Input = styled.input`
  width: 100%;
  height: 48px;
  padding-left: 16px;
  background: #f5f8fa;
  border: 1px solid #d3e2e5;
  border-radius: 5px;
  outline: none;
  color: #5c8599;
`;

export const Textarea = styled.textarea`
  width: 100%;
  height: 200px;
  padding: 16px 16px;
  background: #f5f8fa;
  border: 1px solid #d3e2e5;
  border-radius: 5px;
  outline: none;
  color: #5c8599;
`;

export const Button = styled.button`
  margin-top: 64px;

  width: 100%;
  height: 64px;
  border: 0;
  cursor: pointer;
  background: #5c8599;
  border-radius: 5px;
  color: #ffffff;
  font-weight: 800;

  display: flex;
  justify-content: center;
  align-items: center;

  transition: background-color 0.2s;
`;
