import BlogCoverHeader from "components/BlogHeader";
import Footer from "components/Footer";
import React from "react";
import { Outlet } from "react-router-dom";

import { Container } from "./styles";

export const BlogDefaultLayout: React.FC = () => {
  return (
    <Container>
      <BlogCoverHeader />
      <Outlet />
      <Footer />
    </Container>
  );
};
