import React from 'react';

import {
  BigTitle,
  Container,
  FooterTitle,
  Item,
  ItensContainer,
  LinkS,
  Paragraph,
  SubTitle,
  TextContainer,
} from './styles';
/* import { data } from "./data"; */

const Terms: React.FC = () => {
  /* const { title, text } = true ? data.ptBr : data.en; */
  return (
    <Container>
      <TextContainer>
        <BigTitle>Termos de Uso</BigTitle>
        <SubTitle>1. Termos</SubTitle>
        <Paragraph>
          Ao acessar ao site <a href="/">estrng.com</a>, concorda em cumprir
          estes termos de serviço, todas as leis e regulamentos aplicáveis e
          concorda que é responsável pelo cumprimento de todas as leis locais
          aplicáveis. Se você não concordar com algum desses termos, está
          proibido de usar ou acessar este site. Os materiais contidos neste
          site são protegidos pelas leis de direitos autorais e marcas
          comerciais aplicáveis.
        </Paragraph>
        <SubTitle>2. Uso de Licença</SubTitle>
        <Paragraph>
          É concedida permissão para baixar temporariamente uma cópia dos
          materiais (informações ou software) no site estrongbelgier.com ,
          apenas para visualização transitória pessoal e não comercial. Esta é a
          concessão de uma licença, não uma transferência de título e, sob esta
          licença, você não pode:
        </Paragraph>
        <Paragraph>
          <ItensContainer>
            <Item>Modificar ou copiar os materiais;</Item>
            <Item>
              Usar os materiais para qualquer finalidade comercial ou para
              exibição pública (comercial ou não comercial);
            </Item>
            <Item>
              Tentar descompilar ou fazer engenharia reversa de qualquer
              software contido no site estrongbelgier.com;
            </Item>
            <Item>
              Remover quaisquer direitos autorais ou outras notações de
              propriedade dos materiais; ou
            </Item>
            <Item>
              Transferir os materiais para outra pessoa ou 'espelhe' os
              materiais em qualquer outro servidor.
            </Item>
          </ItensContainer>
        </Paragraph>
        <Paragraph>
          Esta licença será automaticamente rescindida se você violar alguma
          dessas restrições e poderá ser rescindida por estrongbelgier.com a
          qualquer momento. Ao encerrar a visualização desses materiais ou após
          o término desta licença, você deve apagar todos os materiais baixados
          em sua posse, seja em formato eletrónico ou impresso.
        </Paragraph>
        <SubTitle>3. Isenção de responsabilidade</SubTitle>
        <ItensContainer>
          <Item>
            Os materiais no site da estrongbelgier.com são fornecidos 'como
            estão'. estrongbelgier.com não oferece garantias, expressas ou
            implícitas, e, por este meio, isenta e nega todas as outras
            garantias, incluindo, sem limitação, garantias implícitas ou
            condições de comercialização, adequação a um fim específico ou não
            violação de propriedade intelectual ou outra violação de direitos.
          </Item>
          <Item>
            Além disso, o estrongbelgier.com não garante ou faz qualquer
            representação relativa à precisão, aos resultados prováveis ou à
            confiabilidade do uso dos materiais em seu site ou de outra forma
            relacionado a esses materiais ou em sites vinculados a este site.
          </Item>
        </ItensContainer>
        <SubTitle>4. Limitações</SubTitle>
        <Paragraph>
          Em nenhum caso o estrongbelgier.com ou seus fornecedores serão
          responsáveis por quaisquer danos (incluindo, sem limitação, danos
          por perda de dados ou lucro ou devido a interrupção dos negócios)
          decorrentes do uso ou da incapacidade de usar os materiais em
          estrongbelgier.com, mesmo que estrongbelgier.com ou um representante
          autorizado da estrongbelgier.com tenha sido notificado oralmente ou
          por escrito da possibilidade de tais danos. Como algumas jurisdições
          não permitem limitações em garantias implícitas, ou limitações de
          responsabilidade por danos conseqüentes ou incidentais, essas
          limitações podem não se aplicar a você.
        </Paragraph>
        <SubTitle>5. Precisão dos materiais</SubTitle>
        <Paragraph>
          Os materiais exibidos no site da estrongbelgier.com podem incluir
          erros técnicos, tipográficos ou fotográficos. estrongbelgier.com não
          garante que qualquer material em seu site seja preciso, completo ou
          atual. estrongbelgier.com pode fazer alterações nos materiais contidos
          em seu site a qualquer momento, sem aviso prévio. No entanto,
          estrongbelgier.com não se compromete a atualizar os materiais.
        </Paragraph>
        <SubTitle>6. Links</SubTitle>
        <Paragraph>
          O estrongbelgier.com não analisou todos os sites vinculados ao seu
          site e não é responsável pelo conteúdo de nenhum site vinculado. A
          inclusão de qualquer link não implica endosso por estrongbelgier.com
          do site. O uso de qualquer site vinculado é por conta e risco do
          usuário.
        </Paragraph>
        <FooterTitle>Modificações</FooterTitle>
        <Paragraph>
          O estrongbelgier.com pode revisar estes termos de serviço do site a
          qualquer momento, sem aviso prévio. Ao usar este site, você concorda
          em ficar vinculado à versão atual desses termos de serviço.
        </Paragraph>
        <FooterTitle>Lei aplicável</FooterTitle>
        <Paragraph>
          Estes termos e condições são regidos e interpretados de acordo com as
          leis do estrongbelgier.com e você se submete irrevogavelmente à
          jurisdição exclusiva dos tribunais naquele estado ou localidade.
        </Paragraph>
      </TextContainer>
      <LinkS to="/">Voltar</LinkS>
    </Container>
  );
};

export default Terms;
//TODO: Fazer a ajustes para não ficar muito grande com textos.
//TODO: Mudar os termos para estrng.com e nao estrongbelgier.com.
