import React from "react";

import { Container, LinkS } from "./styles";

const Investor: React.FC = () => {
  return (
    <Container>
      <h1>Investidores</h1>
      <p>
        <strong>1. Introdução</strong>
      </p>
      <br />
      <LinkS to="/">Voltar</LinkS>
    </Container>
  );
};

export default Investor;
// TODO: Estratégia para conseguir investidores para o grupo.
