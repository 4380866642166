import dashboard from "../../images/dashboard.svg";
import heapmap from "../../images/heapmap.svg";
import mobile from "../../images/mobile.svg";
import webapp from "../../images/webapp.svg";

export const cards = [
  {
    id: 1,
    to: "landing",
    image: heapmap,
    active: true,
    ptBr: {
      cardTitle: "Landing pages",
      smallText: "Converta seus visitantes em clientes!",
      explantion:
        "Landing Page é uma página que possui todos os elementos voltados à conversão do visitante em Lead, oportunidade ou cliente. Também conhecidas como páginas de aterrissagem ou páginas de conversão, as Landing Pages são muito usadas em campanhas de Marketing Digital, pois costumam ter altas taxas de conversão.",
    },
    en: {
      cardTitle: "Landing pages",
      smallText: "Turn your visitors in customers!",
      explantion:
        "Landing Page is a page that contains all the elements that are aimed at converting the visitor into a Lead, Opportunity or Customer. Also known as Landing Pages, they are very often used in Digital Marketing campaigns, because they often have high conversion rates.",
    },
  },

  {
    id: 2,
    to: "webapp",
    image: webapp,
    active: false,
    ptBr: {
      cardTitle: "Web App (SPA)",
      smallText:
        "Site altamente fucional, dinâmico e com cadastro de clientes.",
      explantion: "Explicação",
    },
    en: {
      cardTitle: "Web App (SPA)",
      smallText:
        "Highly functional website with customer retention and registration.",
      explantion: "eplanation",
    },
  },

  {
    id: 3,
    to: "mobile",
    image: mobile,
    active: false,
    ptBr: {
      cardTitle: "Mobile App",
      smallText: "Aplicativo com a cara da sua empresa para Android e IOS.",
      explantion: "Explicação",
    },
    en: {
      cardTitle: "Mobile App",
      smallText:
        "Mobile Application with your company's brand for Android and IOS.",
      explantion: "eplanation",
    },
  },

  {
    id: 4,
    to: "dashboard",
    image: dashboard,
    active: false,
    ptBr: {
      cardTitle: "Dashboards KPI",
      smallText: "Automação, análise de dados e implementação de indicadores.",
      explantion: "Explicação",
    },
    en: {
      cardTitle: "Dashboards KPI",
      smallText: "Automation, data analysis and implementation of indicators.",

      explantion: "eplanation",
    },
  },
];
